@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  
  body {
    @apply font-Poppins text-[#333333] bg-white;
  }
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-Josefin text-[#333333] font-bold;
  }
  
  p {
    @apply text-[#333333];
  }
  
  /* Custom scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f8f9fa;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #4a41d8;
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #6a00c2;
  }
}

@layer components {
  .underline {
    @apply w-[75px] h-[3px] bg-colorOrange;
  }
  
  .gradient-text {
    @apply text-transparent bg-clip-text;
    background-image: linear-gradient(to right, #4a41d8, #38b99e);
  }
  
  .btn-primary {
    @apply px-6 py-3 bg-colorBrand text-white rounded-lg transition-all duration-300 hover:shadow-lg hover:translate-y-[-2px];
  }
  
  .btn-secondary {
    @apply px-6 py-3 bg-transparent border-2 border-colorBrand text-colorBrand rounded-lg transition-all duration-300 hover:bg-colorBrand hover:text-white;
  }
  
  .btn-cta {
    @apply px-6 py-3 bg-colorCallToAction text-white rounded-lg transition-all duration-300 hover:shadow-lg hover:translate-y-[-2px];
  }
  
  .card {
    @apply bg-white rounded-xl shadow-md p-6 transition-all duration-300 hover:shadow-xl;
  }
  
  .section-padding {
    @apply py-16 md:py-24;
  }
  
  .container-custom {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
  }
  
  /* Text color utility classes */
  .text-darker {
    @apply text-[#333333];
  }
  
  .text-medium {
    @apply text-[#555555];
  }
  
  .text-lighter {
    @apply text-[#777777];
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
  padding: 1rem;
  animation: fadeIn 0.3s ease-out;
}

.popup-for-syllabus {
  position: fixed; /* Stay in place */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure overlay is on top */
  backdrop-filter: blur(5px);
}

.explore-us-button {
  @apply bg-blue-500 text-white px-4 py-2 rounded-md;
  position: relative;
}

.arrow {
  @apply absolute right-0 top-0 h-full w-0;
  transition: transform 0.3s ease-in-out;
}

.explore-us-button:hover .arrow {
  @apply transform rotate-90;
}

/* Glass morphism effect */
.glass {
  @apply bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg border border-white border-opacity-20 shadow-lg;
}

/* Animation utilities */
.animate-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.animate-slide-up {
  animation: slideUp 0.5s ease-in-out;
}

.animate-slide-down {
  animation: slideDown 0.5s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes slideDown {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Gradient animation for footer headings */
@keyframes gradient-x {
  0% { background-position: 0% 0; }
  100% { background-position: 100% 0; }
}

.animate-gradient-x {
  animation: gradient-x 3s linear infinite;
}

/* Subtle pulse animation for social icons */
@keyframes pulse-slow {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.8; }
}

.animate-pulse-slow {
  animation: pulse-slow 4s ease-in-out infinite;
}

/* Subtle bounce animation for scroll to top */
@keyframes bounce-subtle {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.animate-bounce-subtle {
  animation: bounce-subtle 2s ease-in-out infinite;
}

/* Footer specific animations */
@keyframes float-subtle {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-3px); }
}

.animate-float-subtle {
  animation: float-subtle 3s ease-in-out infinite;
}

@keyframes glow {
  0%, 100% { text-shadow: 0 0 5px rgba(59, 130, 246, 0.3), 0 0 10px rgba(59, 130, 246, 0.2); }
  50% { text-shadow: 0 0 10px rgba(59, 130, 246, 0.5), 0 0 20px rgba(59, 130, 246, 0.3); }
}

.animate-glow {
  animation: glow 3s ease-in-out infinite;
}

/* Enhanced shadow glow effect for footer headings */
.shadow-glow {
  text-shadow: 0 0 10px rgba(59, 130, 246, 0.3), 0 0 20px rgba(59, 130, 246, 0.2);
  transition: text-shadow 0.3s ease;
}

.shadow-glow:hover {
  text-shadow: 0 0 15px rgba(59, 130, 246, 0.5), 0 0 30px rgba(59, 130, 246, 0.3);
}